import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import Box from '@mui/material/Box';
import { IntroInfosProps } from '@molecules/IntroInfos/IntroInfos.props';
import { useBreakpoint } from '@hooks/useBreakpoint';

const Chip = dynamic(() => import('@mui/material/Chip'));
const Divider = dynamic(() => import('@mui/material/Divider'));
const Icon = dynamic(() => import('@mui/material/Icon'));
const Typography = dynamic(() => import('@mui/material/Typography'));
const SocialShareButton = dynamic(() => import('@atoms/SocialShareButton/SocialShareButton'));

const IntroInfos = (props: IntroInfosProps) => {
  const { showShareButton, socials, tags, category, timeToRead } = props;

  const { isLarger: isDesktop } = useBreakpoint('lg');

  if (!showShareButton && !tags?.length && !category && !timeToRead) return null;

  return (
    <Box
      gap={theme.spacing(28)}
      display="flex"
      flexDirection="column"
      my={theme.spacing(40)}
      sx={{
        ':after, :before': {
          content: "''",
          height: theme.spacing(2),
          width: '100%',
          background: 'linear-gradient(to right, #FFFFFF1A, #88A2AA66, #88A2AA, #88A2AA66, #FFFFFF1A)',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="center"
        gap={{ xs: theme.spacing(16), lg: theme.spacing(24) }}
      >
        {category && <Chip variant="light" label={category.toUpperCase()} />}

        <Box display="flex" alignItems="center" gap={theme.spacing(24)}>
          {timeToRead && (
            <>
              {category && isDesktop && <Divider flexItem variant="separator" orientation="vertical" />}
              <Box color="secondary.600" display="flex" alignItems="center" gap={theme.spacing(8)}>
                <Icon baseClassName="material-icons-outlined">timer</Icon>
                <Typography variant="bodyS" color="grey.900">
                  {timeToRead} min
                </Typography>
              </Box>
            </>
          )}

          {showShareButton && (
            <>
              {(category || timeToRead) && <Divider flexItem variant="separator" orientation="vertical" />}
              <SocialShareButton socials={socials} />
            </>
          )}
        </Box>

        {tags && (
          <>
            {(category || timeToRead || showShareButton) && isDesktop && (
              <Divider flexItem variant="separator" orientation="vertical" />
            )}
            <Box display="flex" gap={theme.spacing(8)}>
              {tags.map((tag, index) => (
                <Chip variant="dark" key={index} label={`#${tag.toUpperCase()}`} />
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default IntroInfos;
